<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW COFP ACCOUNTS</h4></v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-4">
          <v-flex xs12 md12>
            <v-select
              class="mx-2"
              v-model="branch_id"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              label="Branch"
              required
              :rules="rules.combobox_rule"
              dense
              outlined
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="damayan_last_name"
              label="Plan Holder Last Name"
              dense
              outlined
              :rules="rules.default_max_75_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="damayan_first_name"
              label="Plan Holder First Name"
              dense
              outlined
              :rules="rules.default_max_75_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="damayan_middle_name"
              label="Plan Holder Middle Name"
              dense
              outlined
              :rules="rules.default_max_75_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              class="mx-2"
              v-model="plan_type"
              :items="plan_type_items"
              item-value="plan_type"
              item-text="plan_type"
              label="Plan Type"
              required
              :rules="rules.combobox_rule"
              dense
              outlined
              @change="selected_plan"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="maf_no"
              label="Maf No"
              dense
              type="number"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="cofp_no"
              label="COFP #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="contact_no"
              label="Contact #"
              dense
              outlined
              min="0"
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              min="0"
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>

          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="save_request" v-if="!saving_data"> Save
              Changes
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers"
                        :items="active_items"
                        disable-pagination
                        hide-default-footer
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>

                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{
                  active_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id) + 1
                  }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.branch.branch_code }}
                </td>
                <td>
                  {{ item.maf_no+'-'+item.plan_type }}
                </td>
                <td>
                  {{ item.cofp_no }}
                </td>
                <td>
                  {{ item.amount }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table dense
                        class="mt-3"
                        :headers="headers"
                        :items="deactive_items"
                        disable-pagination
                        hide-default-footer
                        height="550"
                        :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-text-field
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  label="Search"
                  single-line
                  hide-details
                  v-model="search"
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{
                  deactive_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id) + 1
                  }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.branch.branch_code }}
                </td>
                <td>
                  {{ item.maf_no+'-'+item.plan_type }}
                </td>
                <td>
                  {{ item.cofp_no }}
                </td>
                <td>
                  {{ item.amount }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiPlusBox
    , mdiPencil
    , mdiAccountSearchOutline
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {P} from 'caniuse-lite/data/agents'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      saving_request: false,
      edit_dialog: false,
      alert_request: false,
      alert_message_request: '',

      contact_no: '',
      search: '',

      maf_no: '',
      cofp_no: '',
      plan_type: '',
      plan_type_items: [{plan_type: 'RUBY', amount: 28800}, {
        plan_type: 'TOPAZ',
        amount: 25500
      }, {plan_type: 'EMERALD', amount: 39000}, {
        plan_type: 'SAPPHIRE',
        amount: 33000
      }, {plan_type: 'AMBER', amount: 43200}, {
        plan_type: 'OPAL',
        amount: 54000
      }, {plan_type: 'ZIRCON', amount: 39000}, {plan_type: 'CITRINE', amount: 48000}],
      branch_id: '',
      branch_items: [],

      active_items: [],
      deactive_items: [],
      address: '',
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiCheck},
        {title: 'DEACTIVATED', icon: mdiClose},
      ],
      account_no: '',
      bank_code: '',
      damayan_last_name: '',
      damayan_first_name: '',
      damayan_middle_name: '',
      is_adding: false,

      add_casket_dialog: false,
      casket_amount: '0',
      casket_type: '',
      amount: 0,
      tie_up_id: -1,
      tie_up_details: {},

      headers: [
        {text: 'No.', value: 'id', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Branch #', value: 'branch.branch_code', sortable: false},
        {text: 'Bank Code', value: 'bank_code', sortable: false},
        {text: 'COFP #', value: 'cofp_no', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPlusBox,
          mdiPencil,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('tie_ups', ['create_damayan_cofp', 'list_of_damayan_cofp']),
      ...mapActions('branch_information', ['branch_data']),
      initialize_data() {
        this.branch_data({
          category_id: 4,
          is_region: 0,
          is_territory: 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
            this.branch_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
        this.list_of_damayan_cofp()
          .then(response => {
            this.active_items = response.data[0].active
            this.deactive_items = response.data[0].deactivated
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      selected_plan() {
        this.amount = this.plan_type_items[this.plan_type_items
          .map(function (x) {
            return x.plan_type
          })
          .indexOf(this.plan_type)].amount
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const str = this.contact_no;
          const firstTwoChars = str.slice(0, 2);
          if (firstTwoChars != '09') {
            this.alert = true
            this.alert_message = 'CONTACT # MUST START 09'
            this.saving_data = false
          } else {
            const data = new FormData()
            data.append('branch_id', this.branch_id)
            data.append('maf_no', this.maf_no)
            data.append('plan_type', this.plan_type)
            data.append('cofp_no', this.cofp_no)
            data.append('contact_no', this.contact_no)
            data.append('amount', this.amount)
            data.append('name', this.damayan_last_name.toUpperCase() + ', ' + this.damayan_first_name.toUpperCase() + ' ' + this.damayan_middle_name.toUpperCase())
            this.create_damayan_cofp(data)
              .then(response => {
                var color = 'error'
                if (response.status === 200) {
                  var color = 'success'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving_data = false
                Object.assign(this.$data, initialState())
                this.initialize_data()
              })
              .catch(error => {
                console.log(error)
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
